import * as React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { Text, Paragraph, Heading, Box } from "theme-ui"

export const SectionHeader = ({ children }) => (
  <Heading
    as={"h1"}
    sx={{
      position: "relative",
      textAlign: "center",
      ":after": {
        variant: "after.middle",
      },
      my: [1, 3],
    }}
  >
    {children}
  </Heading>
)

export const SectionParagraph = ({ children }) => (
  <Paragraph sx={{ p: 3 }}>{children}</Paragraph>
)

export const Orange = ({ text }) => (
  <Text sx={{ color: "primary" }}>{text}</Text>
)
