import * as React from "react"

import Layout from "components/layout"
import Seo from "components/seo"
import { Flex, Box, Text, Grid } from "theme-ui"
import { SectionHeader } from "components/various"
import { StaticImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import TextLoop from "components/TextLoop"

const usefulLinks = [
  {
    logo: (
      <StaticImage
        sx={{ my: "auto" }}
        loading="eager"
        src="../images/logos/rne.png"
      />
    ),
    name: "Registre National des Entreprises",
    link: "www.registre-entreprises.tn",
    fullLink: "https://www.registre-entreprises.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/oect.png" />,
    name: "Ordre des Experts Comptables de Tunisie",
    link: "www.oect.org.tn",
    fullLink: "https://www.oect.org.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/ins.png" />,
    name: "Institue National des Statistiques",
    link: "www.ins.tn",
    fullLink: "http://www.ins.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/ifac.png" />,
    name: "International Federation of Accountants (IFAC)",
    link: "www.ifac.org",
    fullLink: "https://www.ifac.org/",
  },
  {
    logo: (
      <StaticImage
        loading="eager"
        src="../images/logos/ministere_finance.png"
      />
    ),
    name: "Ministère des Finances",
    link: "www.finances.gov.tn",
    fullLink: "http://www.finances.gov.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/cnss.png" />,
    name: "Caisse Nationale de Sécurité Sociale (CNSS)",
    link: "www.cnss.tn",
    fullLink: "https://www.cnss.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/bvmt.png" />,
    name: "Bourse des Valeurs Mobilières de Tunisie (BVMT)",
    link: "www.bvmt.com.tn",
    fullLink: "https://www.bvmt.com.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/cepex.jpg" />,
    name: "Centre de Promotion des Exportations de la Tunisie (CEPEX)",
    link: "www.cepex.nat.tn",
    fullLink: "http://www.cepex.nat.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/bct.png" />,
    name: "Banque centrale de Tunisie (BCT)",
    link: "www.bct.gov.tn",
    fullLink: "https://www.bct.gov.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/aneti.png" />,
    name: "Agence Nationale pour l’Emploi et le Travail indépendant (ANETI)",
    link: "www.emploi.nat.tn",
    fullLink: "https://www.emploi.nat.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/apia.png" />,
    name: "Agence de promotion des investissements agricoles (APIA)",
    link: "www.apia.com.tn",
    fullLink: "http://www.apia.com.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/apii.png" />,
    name: "Agence de la promotion de l'industrie et de l'innovation (API)",
    link: "www.tunisieindustrie.nat.tn",
    fullLink: "http://www.tunisieindustrie.nat.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/fipa.png" />,
    name: "Agence de Promotion de l'Investissement Extérieur (FIPA)",
    link: "www.investintunisia.tn",
    fullLink: "http://www.investintunisia.tn/",
  },

  {
    logo: <StaticImage loading="eager" src="../images/logos/cbf.jpg" />,
    name: "Conseil Bancaire et Financier",
    link: "www.apbt.org.tn",
    fullLink: "https://www.apbt.org.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/impot.png" />,
    name: "L'Administration Fiscale du Ministère des Finances de la République Tunisienne",
    link: "www.impots.finances.gov.tn",
    fullLink: "http://www.impots.finances.gov.tn/",
  },
  {
    logo: <StaticImage loading="eager" src="../images/logos/cmf.png" />,
    name: "Conseil du Marche Financier",
    link: "www.cmf.tn",
    fullLink: "https://www.cmf.tn/",
  },
]

const quotes = [
  "La sagesse est fille de l'expérience",
  "La valeur d'un homme tient dans sa capacité à donner et non à recevoir",
  "Il faut essayer de faire un petit peu plus que ce dont on croit être capable",
  "Le vrai patron est quelqu'un qui se mêle passionnément de votre travail, qui le fait avec vous, par vous",
  "De la considération des obstacles vient l'échec. De la considération des moyens la réussite",
  "Sans différences, pas d'harmonie",
]

const UsefulLink = ({ logo, name, link, fullLink }) => (
  <motion.a
    whileHover={{
      scale: 1.1,
    }}
    sx={{ color: "primary", cursor: "pointer", textDecoration: "none" }}
    target="_blank"
    rel="noopener noreferrer"
    href={fullLink}
  >
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        py: 2,
        alignContent: "center",
      }}
    >
      <Flex
        sx={{
          maxWidth: 350,
          height: 170,
          userSelect: "none",
          alignItems: "center",
        }}
      >
        {logo}
      </Flex>
      <Box
        sx={{
          textAlign: "center",
          p: 1,
          height: [50, 70],
        }}
      >
        <Text sx={{ color: "black", fontWeight: "bold" }}>{name}</Text>
      </Box>
      <Text sx={{ mt: 2, textDecoration: "underline", fontWeight: "bold" }}>
        {link}
      </Text>
    </Flex>
  </motion.a>
)

const UsefulLinkPage = () => (
  <Layout>
    <Seo title="Team" />
    <StaticImage
      src="../images/useful_links.jpg"
      loading="eager"
      quality={95}
      formats={["auto", "webp", "avif"]}
      sx={{ width: "full", height: [179, 300] }}
    />
    <Flex
      sx={{
        flexDirection: "column",
        alignItems: "center",
        my: 3,
        p: 3,
        px: [2, 3, 3, 5],
        justifyContent: "space-around",
      }}
    >
      <SectionHeader>Liens Utiles</SectionHeader>
      <Grid
        sx={{
          mt: [4, 5],
          width: "full",
          rowGap: 4,
          columnGap: 3,
        }}
        columns={[
          [1, "1fr"],
          [2, "1fr 1fr"],
          [3, "1fr 1fr 1fr"],
        ]}
      >
        {usefulLinks.map(({ logo, name, link, fullLink }) => (
          <UsefulLink logo={logo} name={name} link={link} fullLink={fullLink} />
        ))}
      </Grid>
    </Flex>
    <TextLoop texts={quotes} />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Useful Links" />

export default UsefulLinkPage
